/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {
  theme,
  Box,
  horizontalSpace,
  verticalSpace,
  columnWidth
} from 'design-system'
import { SearchIcon, SearchCloseIcon } from 'assets/svg'
import { DisableScroll } from 'utils'

import { Button } from '../Button'
import { SearchModal } from './SearchModal'

// Search component
export const Search = ({
  isDesktop,
  isMobileOpen,
  handleSearchOpen,
  handleIsSearching,
  siteTheme,
  hideSideNav
}) => {
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [prevPath] = useState('')

  const handleSearchToggle = (event) => {
    if (event) event.currentTarget.blur()
    setSearchOpen(!isSearchOpen)
    handleSearchOpen(!isSearchOpen)
  }

  const handleSearchDisplay = (searching) => {
    if (isSearching !== searching) {
      setIsSearching(searching)
      handleIsSearching(searching)
    }
  }

  useEffect(() => {
    if (isSearchOpen && prevPath !== window.location.href) handleSearchToggle()
  }, [typeof window !== 'undefined' && window.location.href])

  return (
    <>
      <Box
        display={['none', 'none', 'inline-block']}
        position={isSearchOpen ? 'fixed' : 'relative'}
        right={0}
        left={hideSideNav ? 0 : 'auto'}
        height='auto'
        width='auto'
        textAlign='right'
        mr={isSearchOpen ? '10vw' : horizontalSpace.small}
        mt={isSearchOpen ? horizontalSpace.xxSmall : 0}
        zIndex={15}
      >
        <Button
          hideArrow
          theme={siteTheme}
          label='Global Search Icon'
          height={isSearchOpen ? verticalSpace.medium : 'auto'}
          css={`
            button {
              line-height: 0px !important;
            }
          `}
          onClick={(e) => handleSearchToggle(e)}
        >
          {!isSearchOpen ? (
            <SearchIcon display='block' />
          ) : (
            <SearchCloseIcon display='block' />
          )}
        </Button>
      </Box>
      <Box
        display={
          (isDesktop && isSearchOpen) || (!isDesktop && isMobileOpen)
            ? 'block'
            : 'none'
        }
        position={isDesktop ? 'absolute' : 'relative'}
        top={0}
        left={hideSideNav ? 0 : columnWidth(2)}
        height={['auto', 'auto', '100vh']}
        minHeight={isDesktop ? '10vh' : 'unset'}
        width='100vw'
        overflow={isDesktop && 'auto'}
        background={isDesktop ? theme.colors.black : 'transparent'}
      >
        <DisableScroll disable={isSearchOpen && isDesktop} />
        <SearchModal
          // TODO: placeholder text will be a field under navigation in Contentful
          placeholderText="I'd like to know more about..."
          isDesktop={isDesktop}
          isMobileOpen={isMobileOpen}
          isSearchOpen={isSearchOpen}
          toggleDisplay={handleSearchDisplay}
        />
      </Box>
    </>
  )
}
