import { camelize } from '../components/patterns/Camelize';

export const themeMap = {
  black: 'dark',
  white: 'light',
  lightGrey: 'light',
  darkerGrey: 'dark',
  darkGrey: 'dark',
  lightBlue: 'light',
  darkBlue: 'dark',
  rangle: 'dark',
  borrowell: 'dark',
  invesco: 'dark',
  fastRetail: 'light',
  progressive: 'light',
  staples: 'dark',
  dsg: 'dark',
  babbly: 'light',
  stripe: 'dark',
  google: 'dark',
  synaptive: 'dark',
  hims: 'light',
};

export const applyTheme = (blocks, parentTheme, backgroundColor) => {
  return blocks.map((block) => ({
    ...block,
    props: {
      ...block.props,
      theme: backgroundColor
        ? themeMap[camelize(backgroundColor)]
        : parentTheme,
    },
  }));
};