import React from 'react'

import { Box, horizontalSpace, fontSize } from 'design-system'
import { Text, FullBleed, Button } from 'components/modules'
import { applyTheme } from '../../utils'
import { camelize } from '../patterns/Camelize'

export const Footer = ({
  upperFooter,
  lowerFooter,
  legalLink,
  theme = 'dark',
  backgroundColour = 'black'
}) => {
  const getYear = () => {
    return new Date().getFullYear()
  }

  const wrapContainer = (section, hasBorder) => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        // This is the remove spacing on desktop
        py={horizontalSpace.xLarge}
        borderBottom={hasBorder ? 1 : 0}
        // theme variables doesn't work here
        borderColor='rgba(233, 233, 233, 0.4)' // borderLight
      >
        {applyTheme(section, theme, backgroundColour)}
      </Box>
    )
  }

  const legalWrapper = (
    <Box mt={['4rem','0rem']}>
      <Box width='100%' position='absolute' bottom='0'>
        <Text theme='dark' type='Legal' textAlign='left' paddingH='layout'>
          <Box display={['block', 'inline']} mr={horizontalSpace.small}>
            © Rangle.io, {getYear()}. All Rights Reserved.
          </Box>
          <Box display='inline-block' mb={horizontalSpace.medium}>
            <Button
              className='ot-sdk-show-settings'
              style={{ fontWeight: 'bolder', textDecoration: 'underline' }}
              display='inline'
              fontSize={fontSize.xxSmall}
              theme={theme}
              label='Cookie settings'
            >
              Cookie settings
            </Button>
            <Box display='inherit'>
              {applyTheme([legalLink], theme, backgroundColour)}
            </Box>
          </Box>
        </Text>
      </Box>
    </Box>
  )

  return (
    <footer id='footer'>
      <FullBleed
        backgroundColour={camelize(backgroundColour)}
        blocks={applyTheme(
          [
            wrapContainer(upperFooter, true),
            wrapContainer(lowerFooter.concat(legalWrapper))
          ],
          theme,
          backgroundColour
        )}
      />
    </footer>
  )
}
