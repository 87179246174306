import React from 'react'
import {
  theme as style,
  Box,
  horizontalSpace,
  verticalSpace,
  GlobalStyle
} from 'design-system'
import { NavigationList, Button, Text, NavigationItem } from 'components'
import { withLocation, DisableScroll, withNavState } from 'utils'

import { Search } from './Search'
import { Link } from './Link'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktop: undefined,
      isMobileOpen: false,
      isSearchOpen: false,
      isSearching: false,
      scrollPosition: 'top',
      selectedGroup: this.findSelectedMenu(props)
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleSearchOpen = this.handleSearchOpen.bind(this)
    this.handleIsSearching = this.handleIsSearching.bind(this)
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }

  // close mobile nav if navigating to new page
  componentWillReceiveProps(nextProp) {
    if (
      this.props.location.location.pathname !==
      nextProp.location.location.pathname
    ) {
      this.handleMenuClick(false)
      this.setState({
        selectedGroup: this.findSelectedMenu(nextProp)
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  }

  findSelectedMenu = (props) => {
    const {
      location: {
        location: { pathname }
      },
      items
    } = props
    let matchedMenuItem
    items.find(({ props: itemProps = {} }) => {
      if (itemProps.subItems) {
        itemProps.subItems.find(({ props: subItemProps = {} }) => {
          if (pathname.startsWith(subItemProps.link)) {
            matchedMenuItem = itemProps
          }
        })
      }
    })
    return matchedMenuItem || {}
  }

  handleScroll = () => {
    const { scrollPosition } = this.state
    let currentScrollPosition
    if (window.pageYOffset === 0) {
      currentScrollPosition = 'top'
    } else if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight
    ) {
      currentScrollPosition = 'bottom'
    }

    if (currentScrollPosition !== scrollPosition) {
      this.setState({
        scrollPosition: currentScrollPosition
      })
    }
  }

  handleResize = () => {
    const isDesktop = window.innerWidth >= style.rawBreakpoints[1]
    if (isDesktop !== this.state.isDesktop) {
      this.setState({
        isDesktop
      })
    }
  }

  handleMenuClick(isMobileOpen) {
    this.setState({
      isMobileOpen
    })
  }

  handleSearchOpen(isSearchOpen) {
    this.setState({ isSearchOpen })
  }

  handleIsSearching(searching) {
    this.setState({ isSearching: searching })
  }

  render() {
    const transitionTime = 300
    const {
      location: {
        location: { pathname }
      },
      items,
      topBlockTheme,
      pageTheme,
      buildContext: { hideSideNav },
      logo
    } = this.props
    const { isDesktop, isSearchOpen, isMobileOpen, scrollPosition } = this.state

    let theme
    let border
    let backgroundColor

    const setStyles = (newTheme) => {
      theme = newTheme
      backgroundColor = newTheme === 'light' ? 'white' : 'black'
      border = newTheme === 'light' ? 'borderDark' : 'borderLight'
    }

    if (isSearchOpen) {
      setStyles('dark')
    } else if (isMobileOpen) {
      setStyles('dark')
      border = 'transparent'
      // Page with fullbleed, scrolled to the top
    } else if (scrollPosition === 'top' && topBlockTheme !== 'default') {
      setStyles(topBlockTheme)
      backgroundColor = 'transparent'
      // Desktop
    } else if (isDesktop) {
      setStyles(pageTheme)
      border = pageTheme === 'light' ? 'transparent' : 'borderLight'

      // scrolled to the bottom
      if (scrollPosition === 'bottom') {
        setStyles('dark')
      }
      // Mobile
    } else {
      setStyles('dark')
      border = 'borderLight'
    }

    return (
      <>
        <GlobalStyle />
        <DisableScroll disable={isMobileOpen} />
        {/* Top Nav */}
        <Box
          bg={backgroundColor}
          css={`
            transform: translate3d(0, 0, 0);
            left: 0;
            right: 0;
            transition: background ${transitionTime}ms ease-in;
          `}
          width='100vw'
          py={verticalSpace.medium}
        >
          <Box
            display='inline-block'
            width={['50vw', '50vw', '50vw', '20vw']}
            pl={
              hideSideNav
                ? horizontalSpace.layout
                : [horizontalSpace.layout, horizontalSpace.layout].concat(
                    horizontalSpace.medium.slice(2)
                  )
            }
            css={`
              vertical-align: middle;
            `}
          >
            <Link
              hideArrow
              theme={theme}
              type='medium'
              linkConfig={{ props: { url: 'https://rangle.io' } }}
              colors={`${theme}BackgroundNavLink`}
              css={`
                &:hover .label {
                  text-decoration: none;
                }
              `}
            >
              {logo}
            </Link>
          </Box>
          <Box
            display={['none', 'none', 'none', 'inline-flex']}
            width='80vw'
            px={horizontalSpace.layout}
            // justifyContent='space-between'
            justifyContent='end'
            alignItems='center'
          >
            {/* <Box display='flex'>
              {items.map((item) => {
                return (
                  <NavigationItem
                    isActive={
                      item.props.entryId === this.state.selectedGroup.entryId
                    }
                    mr={verticalSpace.medium}
                    theme={theme}
                    {...item.props}
                  />
                )
              })}
            </Box> */}
            <Box display='inline-flex' alignItems='center'>
              {/* Search */}
              {/* <Search
                siteTheme={theme}
                isDesktop={isDesktop}
                isMobileOpen={isMobileOpen}
                handleSearchOpen={this.handleSearchOpen}
                handleIsSearching={this.handleIsSearching}
                hideSideNav={hideSideNav}
              /> */}
              <Box display='inline-block'>
                <Link
                  theme={theme}
                  type='Secondary Button'
                  hideArrow
                  label='Contact'
                  linkConfig={{
                    props: { url: 'https://rangle.io/contact-us' }
                  }}
                  px='small'
                  py='xSmall'
                />
              </Box>
            </Box>
          </Box>
          {/*  Menu Button */}
          <Box
            display={['inline-flex', 'inline-flex', 'inline-flex', 'none']}
            pr={horizontalSpace.layout}
            width='50vw'
            justifyContent='flex-end'
          >
            <Button
              onClick={() => this.handleMenuClick(!isMobileOpen)}
              mr={horizontalSpace.layout}
              colors={`${theme}BackgroundNavLink`}
              label={`nav-menu-${isMobileOpen ? 'Close' : 'Menu'}`}
            >
              <span tabIndex='-1' className='label' style={{ outline: 'none' }}>
                {isMobileOpen ? 'Close' : 'Menu'}
              </span>
            </Button>
          </Box>
        </Box>
        {/* Side Nav */}
        {this.state.selectedGroup.entryId && !hideSideNav && (
          <Box
            display={['none', 'none', 'none', 'block']}
            position='absolute'
            top='0'
            pt={verticalSpace.xLarge}
            bg={backgroundColor}
            css={`
              transform: translate3d(0, 0, 0);
              left: 0;
              right: 0;
              transition: background ${transitionTime}ms ease-in;
            `}
            width='20vw'
            height='100vh'
            overflow='auto'
            px={verticalSpace.medium}
            zIndex='-1'
          >
            {this.state.selectedGroup.subItems.map((item, index) => {
              const isActive = pathname.startsWith(item.props.link)
              return item.props.componentType === 'text' ? (
                <Text
                  letterSpacing='xLarge'
                  theme={theme}
                  mt={index !== 0 ? verticalSpace.medium : '0px'}
                  pb='small'
                  {...item.props}
                />
              ) : (
                <NavigationItem
                  isSubItem
                  isActive={isActive}
                  theme={theme}
                  {...item.props}
                />
              )
            })}
          </Box>
        )}
        {/* Mobile Nav */}
        <Box
          display={[
            isMobileOpen ? 'block' : 'none',
            isMobileOpen ? 'block' : 'none',
            isMobileOpen ? 'block' : 'none',
            'none'
          ]}
          position='absolute'
          top='0'
          pt={verticalSpace.xLarge}
          bg='black'
          height='100vh'
          width='100vw'
          px={horizontalSpace.layout}
          zIndex='-1'
        >
          <NavigationList pathname={pathname} theme={theme}>
            {items.map((item) => {
              return <NavigationItem theme={theme} {...item.props} />
            })}
          </NavigationList>
        </Box>
      </>
    )
  }
}

export const Navigation = withNavState(withLocation(Nav))
