import { fonts } from './fonts';

export const breakpoints = [768, 1024, 1280, 1440, 1920, 2560, 3840];

// DO NOT USE THESE OLD COLOURS, WILL BE DEPRECIATED
const shades = {
  black: '#262626', // Black - Mine Shaft - RGB: 38, 38, 38
  darkGrey: '#757575', // Dark Grey - Boulder - RGB: 117, 117, 117
  grey: '#E9E9E9', // Grey - Mercury - RGB: 233, 233, 233
  lightGrey: '#FAFAFA', // Light Grey - Alabaster - RGB: 250, 250, 250
  white: '#FFFFFF', // White - RGB: 255, 255, 255
};

const colors = {
  borderLight: 'rgba(233, 233, 233, 0.4)',
  borderDark: 'rgba(101,101,101, 0.4)',
  darkBlue: '#005EB8',
  blue: '#E1E9EE',
  lightBlue: '#F0F4F6',
  black: '#262626',
  darkerGrey: '#343434',
  darkGrey: '#656565',
  grey: '#E9E9E9',
  lightGrey: '#FAFAFA',
  white: '#FFFFFF',
  darkRed: '#950000',
  red: '#D34526',
  lightRed: '#F4E5E5',
  orange: '#F65836',
  rangle: '#D44527',
  borrowell: '#2B38CC',
  invesco: '#193989',
  fastRetail: '#F1F3F4',
  progressive: '#E0E5ED',
  staples: '#BD2827',
  dsg: '#006554',
  babbly: '#E6EEF3',
  stripe: '#32325D',
  google: '#4285F4',
  synaptive: '#000000',
  hims: '#EEE2D6',
  // DO NOT USE THESE OLD COLOURS, WILL BE DEPRECIATED
  brand: [
    '#D34526', // Red/Orange - RGB: 211, 69, 38
    shades.black, // #262626 - Black
    '#E1E9EE', // Blue/Grey - Mystic - RGB: 225, 233, 238
    '#FF5823', // Orange - - RGB: 255, 88, 35
    '#950000', // Dark Red - RGB: 149, 00, 00
  ],
  ui: [
    shades.black, // #262626 - Black
    shades.darkGrey, // #757575 - Dark Grey
    shades.grey, // #E9E9E9 - Grey
    shades.lightGrey, // #FAFAFA - Light Grey,
  ],
  uiDivider: 'rgba(117,117,117,0.5)',
  bg: [
    shades.black, // #262626 - Black
    shades.lightGrey, // #FAFAFA - Light Grey
    shades.white, // #FFFFFF - White
    'rgba(225, 233, 238, 0.2)', // #E1E9EE - Light Blue,
    'rgba(225, 233, 238, 0.5)', // #E1E9EE - Variant: Careers Persistent CTA (Callout),
  ],
  text: [
    shades.black, // #262626 - Black
    shades.grey, // #E9E9E9 - Grey
    shades.lightGrey, // #FAFAFA - Light Grey
    shades.darkGrey, // #757575 - Dark Grey
  ],
  btnText: [
    shades.black, // #262626 - Black
    shades.grey, // #E9E9E9 - Grey
  ],
  btnTextHover: [
    '#D34526', // Red - RGB: 211, 69, 38
    '#F65836', // Orange - RGB: 246, 88, 54 - btn text hover inverse for use on dark grey (colors.ui.2) bg
  ],
  error: ['#950000', '#F4E5E5'],
};

const space = {
  none: '0px',
  xxSmall: '4px',
  xSmall: '8px',
  small: '16px',
  medium: '32px',
  large: '72px',
  xLarge: '112px',
  xxLarge: '144px',
  xxxLarge: '220px',
  xxxxLarge: '412px',
};

// DO NOT USE THESE OLD FONT SIZES, WILL BE DEPRECIATED
const fontSizes = {
  footnote: '12px',
  bodyS: '14px',
  bodyM: '16px',
  bodyL: '20px',
  subHeadingS: '22px',
  subHeadingM: '24px',
  subHeadingL: '26px',
  subHeadingXl: '32px',
  headingS: '48px',
  headingM: '64px',
  headingL: '96px',
  headingXl: '144px',
};

export const columnWidth = (numberOfColumns) => `${numberOfColumns * 10}vw`;

const calcFluidRange = (minSize, maxSize, minV, maxV) => {
  return `calc(${minSize}px + (${maxSize} - ${minSize}) * ((100vw - ${minV}px) / (${maxV} - ${minV})))`;
};

export const createFluidStyle = (mobileSize, tabletSize, DesktopSize) => {
  const mobileToDesktop = calcFluidRange(mobileSize, tabletSize, 320, 1280);
  return [
    mobileToDesktop,
    mobileToDesktop,
    mobileToDesktop,
    calcFluidRange(tabletSize, DesktopSize, 1280, 2200),
  ];
};

export const verticalSpace = {
  none: 'none',
  xxxSmall: createFluidStyle(4, 4, 6),
  xxSmall: createFluidStyle(8, 8, 12),
  xSmall: createFluidStyle(16, 16, 24),
  small: createFluidStyle(16, 24, 36),
  medium: createFluidStyle(24, 32, 48),
  large: createFluidStyle(32, 48, 72),
  largePlus: createFluidStyle(48, 96, 144),
  xLarge: createFluidStyle(72, 144, 216),
  xxLarge: createFluidStyle(144, 288, 432),
  topNavSpacing: createFluidStyle(69, 89, 133)
    .slice(0, 2)
    .concat(['0px']), // slice and concat to remove topnav-offset spacing on desktop
};

export const horizontalSpace = {
  none: 'none',
  layout: columnWidth(1),
  quote: ['medium', 'xLarge', 'xxLarge', 'xxxLarge'],
  ...verticalSpace,
};

export const getOffset = (offset) =>
  verticalSpace[offset]
    ? verticalSpace[offset].map((spacing) => `calc(-1 * ${spacing})`)
    : 'none';

export const fontSize = {
  xLarge: createFluidStyle(23, 36, 64),
  large: createFluidStyle(18, 25, 42),
  medium: createFluidStyle(17, 20, 34),
  small: createFluidStyle(16, 17, 23),
  xSmall: createFluidStyle(14, 15, 19),
  xxSmall: createFluidStyle(12, 12, 14),
};

const sizes = {
  '210px': '210px',
};

const letterSpacings = {
  small: '0.01em',
  medium: '0.02em',
  large: '0.025em',
  xLarge: '0.075em',
};

const animationTime = [0, 0.2, 0.4, 0.5, 0.8, 1.5];

const transitions = {
  ease: {
    short: `${animationTime[2]}s ease`,
    long: `${animationTime[3]}s ease`,
  },
  easeOut: {
    xShort: `${animationTime[1]}s ease-out`,
    short: `${animationTime[2]}s ease-out`,
    long: `${animationTime[3]}s ease-out`,
    xLong: `${animationTime[4]}s ease-out`,
    xxLong: `${animationTime[5]}s ease-out`,
  },
};

const guttersDef = [20, 32];

const guttersHalf = [guttersDef[0] / 2, guttersDef[1] / 2];

export const theme = {
  animationTime,
  transitions,
  space,
  sizes,
  letterSpacings,
  fontSizes,
  // @default mobile 4 col fluid wide with 20px gutters
  // @768px 6 col fluid wide with 32px gutters 768x1024
  // @1024px 8 col fluid wide with 32px gutters 1024x768
  // @1366px 10 col fluid wide with 32px gutters 1366x768
  // @1440px 10 col fluid wide with 32px gutters 1440x1024
  // @1920px 10 col fluid wide with 32px gutters 1920x1080

  rawBreakpoints: breakpoints,
  breakpoints: breakpoints.map((p) => `${p}px`),
  columnWidth: [
    1 / 4, // @default
    1 / 6, // @768px
    1 / 8, // @1024px
    1 / 10, // @1366px and above
  ],
  gutters: [
    // Half gutters so divide it by two
    ...guttersHalf,
  ],
  doubleGutters: [
    // gutters not divided by two
    ...guttersDef,
  ],
  verticalSpace: {
    none: 0,
    xxxSmall: '2px',
    xxSmall: '4px',
    xSmall: '8px',
    small: 16,
    medium: 32,
    mediumLarge: 60,
    large: 72,
    xLarge: 144,
  },
  rawFontSizes: [
    '12px', // 0
    '14px', // 1
    '16px', // 2
    '20px', // 3
    '24px', // 4
    '32px', // 5
    '48px', // 6
    '56px', // 7
    '72px', // 8 - removed in new
    '96px', // 9
    '144px', // 10
  ],
  lineHeights: [
    '12px', // 0
    '16px', // 1
    '18px', // 2
    '20px', // 3
    '24px', // 4
    '28px', // 5
    '32px', // 6
    '48px', // 7
    '56px', // 8
    '64px', // 9
    '104px', // 10
    '112px', // 11
    '144px', // 12
    '1em', // 13
    '1.3em', // 14
    '1.6em', // 15
    '1.2em', // 16
  ],

  fonts: {
    'rangle riforma': fonts.riforma,
    regular: fonts.regular, // to be deprecated
    light: fonts.light, // to be deprecated
  },
  fontWeights: {
    normal: 'normal',
    bold: 'bold',
    light: 300,
    regular: 400,
    medium: 500,
  },
  borders: [
    0,
    `1px solid ${colors.grey}`,
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
    `1px solid rgba(101,101,101, 0.5)`, // colors.darkGrey
  ],
  zIndexes: {
    menuBar: 1100,
    drawer: 1200,
    modal: 1300,
    tooltip: 1500,
    skipLink: 2000,
  },
  colors,
  colorStyles: {
    // START DEPRECATED: @see new usage below
    lightBackgroundHoverText: {
      color: colors.black,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover': {
        color: colors.red,
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.red,
        outlineWidth: '2px',
      },
    },

    lightBackgroundNavLink: {
      color: colors.darkGrey,
      textDecoration: 'none',
      cursor: 'pointer',
      '&.active, &:hover': {
        color: '#000000',
      },
      '&.active .label, &:hover .label': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.black,
        outlineWidth: '2px',
      },
    },

    darkBackgroundNavLink: {
      color: colors.white,
      opacity: 0.8,
      textDecoration: 'none',
      cursor: 'pointer',
      '&.active, &:hover': {
        opacity: 1,
      },
      '&.active .label, &:hover .label': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.white,
        outlineWidth: '2px',
      },
    },

    darkBackgroundHoverText: {
      color: colors.white,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover': {
        color: colors.orange,
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.orange,
        outlineWidth: '2px',
      },
    },

    lightBackgroundButton: {
      backgroundColor: colors.black,
      color: colors.white,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `background-color ${transitions.easeOut.short}`,
      '&:hover, &:focus, &.active': {
        backgroundColor: colors.red,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
      },
      '&:hover .label, &.active .label': {
        textDecoration: 'none',
      },
    },

    darkBackgroundButton: {
      backgroundColor: colors.white,
      color: colors.black,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `background-color ${transitions.easeOut.short}`,
      '&:hover, &:focus, &.active': {
        backgroundColor: colors.red,
        color: colors.white,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
      },
      '&:hover .label, &.active .label': {
        textDecoration: 'none',
      },
    },

    lightBackgroundSecondaryButton: {
      color: colors.black,
      border: `1px solid ${colors.black}`,
      textDecoration: 'none',
      '&:hover, &:focus, &.active': {
        color: colors.white,
        backgroundColor: colors.red,
        border: `1px solid ${colors.red}`,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
        transition: `background-color ${transitions.easeOut.short}`,
      },
    },

    darkBackgroundSecondaryButton: {
      color: colors.white,
      border: `1px solid ${colors.white}`,
      textDecoration: 'none',
      '&:hover, &:focus, &.active': {
        color: colors.white,
        backgroundColor: colors.red,
        border: `1px solid ${colors.red}`,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
        transition: `background-color ${transitions.easeOut.short}`,
      },
    },

    lightBackgroundCTAs: {
      color: colors.black,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover, &.active': {
        color: colors.red,
      },
      '&:hover .label, &.active .label': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.red,
        outlineWidth: '2px',
      },
    },

    darkBackgroundCTAs: {
      color: colors.white,
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover, &.active': {
        color: colors.orange,
      },
      '&:hover .label, &.active .label': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineColor: colors.orange,
        outlineWidth: '2px',
      },
    },

    lightInlineLink: {
      color: 'inherit',
      textDecoration: 'underline',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover': {
        color: colors.red,
      },
      '&:focus': {
        outlineColor: colors.red,
        outlineWidth: '2px',
      },
    },

    darkInlineLink: {
      color: 'inherit',
      textDecoration: 'underline',
      transition: `color ${transitions.easeOut.short}`,
      '&:hover': {
        color: colors.orange,
      },
      '&:focus': {
        outlineColor: colors.orange,
        outlineWidth: '2px',
      },
    },

    lightAccordionSummary: {
      color: colors.text[0],
      hover: {
        color: colors.red,
      },
    },

    darkBackgroundHeadings: {
      color: colors.grey,
    },

    selectOption: {
      color: colors.black,
      cursor: 'pointer',
      textDecoration: 'none',
      outline: 'none',
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.red,
      },
      '&:focus': {
        outlineColor: colors.red,
        outlineWidth: '2px',
      },
      '&[data-selected=true]': {
        color: colors.white,
        backgroundColor: colors.red,
      },
    },
    lightEmailCapture: {
      border: `1px solid ${colors.darkGrey}`,
      '&:focus-within': {
        border: `1px solid ${colors.black}`,
      },
    },
    darkEmailCapture: {
      color: 'brown',
      border: `1px solid ${colors.grey}`,
      '&:focus-within': {
        border: `1px solid ${colors.white}`,
      },
    },
    lightEmailCaptureButton: {
      color: colors.darkGrey,
      backgroundColor: colors.white,
      cursor: 'pointer',
      textDecoration: 'none',
      borderLeft: `1px solid ${colors.darkGrey}`,
      transition: `
        color ${transitions.easeOut.short}
        background-color ${transitions.easeOut.short}
      `,
      '*:focus-within > &': {
        borderLeft: `1px solid ${colors.black}`,
        color: colors.black,
      },
      '&:hover, &:focus': {
        color: colors.white,
        backgroundColor: colors.red,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
      },
    },
    darkEmailCaptureButton: {
      color: colors.grey,
      backgroundColor: colors.black,
      cursor: 'pointer',
      textDecoration: 'none',
      borderLeft: `1px solid ${colors.grey}`,
      transition: `
        color ${transitions.easeOut.short}
        background-color ${transitions.easeOut.short}
      `,
      '*:focus-within > &': {
        borderLeft: `1px solid ${colors.white}`,
        color: colors.white,
      },
      '&:hover, &:focus': {
        backgroundColor: colors.red,
        outlineColor: colors.red,
        outlineOffset: '4px',
        outlineWidth: '2px',
        color: colors.white,
      },
    },
  },
  // To depreciate
  typography: {
    light: {
      // regular body copy
      body1: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'], // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.3',
      },
      // empahasized body copy
      body2: {
        fontFamily: 'regular',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.0',
      },

      // h2
      mediumHeading: {
        fontFamily: 'light',
        fontSize: ['bodyL', 'bodyL', 'bodyL', 'subHeadingXl'],
        // old [3, 3, 3, 5]
        lineHeight: [6, 6, 6, '44px'],
        color: 'text.0',
      },
      // h3
      smallHeading: {
        fontFamily: 'regular',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.0',
      },

      caption: {
        fontSize: ['bodyS', 'bodyS', 'bodyS', 'bodyM'],
        // old [1, 1, 1, 2]
        lineHeight: [3, 3, 3, 4],
        color: 'text.3',
        fontFamily: 'light',
      },

      selectOption: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: colors.black,
      },

      placeholder: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: colors.darkGrey,
      },

      textLink: {
        color: 'text.3',
        textDecoration: 'none',
        hover: {
          color: colors.red,
          textDecoration: 'underline',
        },
        focus: {
          outlineColor: colors.red,
          outlineWidth: '2px',
        },
      },

      link: {
        color: 'text.0',
        textDecoration: 'none',
        hover: {
          color: colors.red,
          textDecoration: 'underline',
        },
        focus: {
          outlineColor: colors.red,
          outlineWidth: '2px',
        },
      },

      formFieldError: {
        fontFamily: 'light',
        fontSize: ['bodyS', 'bodyM'],
        // old [1, 2]
        lineHeight: [3, 4],
        color: 'error.0',
      },
    }, // end of light
    dark: {
      body1: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.2',
      },
      body2: {
        fontFamily: 'regular',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.1',
      },

      mediumHeading: {
        fontFamily: 'light',
        fontSize: ['bodyL', 'bodyL', 'bodyL', 'subHeadingXl'],
        // old [3, 3, 3, 5]
        lineHeight: [6, 6, 6, '44px'],
        color: 'text.2',
      },
      smallHeading: {
        fontFamily: 'regular',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.2',
      },

      selectOption: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 2, 2, 3]
        lineHeight: [4, 4, 4, 4, 4, 6],
        color: 'text.1',
      },

      placeholder: {
        fontFamily: 'light',
        fontSize: ['bodyM', 'bodyM', 'bodyM', 'bodyL'],
        // old [2, 2, 2, 3]
        lineHeight: [4, 4, 4, 6],
        color: 'text.2',
      },

      link: {
        color: 'text.2',
        textDecoration: 'none',
        hover: {
          color: colors.btnTextHover[2],
          textDecoration: 'underline',
        },
      },
    },
  },
};