import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { SkipLinksPanel, SkipLink } from 'components'
import { theme, Box, columnWidth } from 'design-system'
import { Providers } from 'layouts/Providers'
import { buildComponent, withLocation, contentfulDataFormatter } from '../utils'
import '../assets/css/screen.css'

import '@rangleio/ds/dist/index.css'

const buildNavigation = (data, navProps = {}) => {
  const [navData] = data.allContentfulNavigationTree.edges
  const spec = {
    ...JSON.parse(navData.node.componentTree)
  }
  return buildComponent(spec, navProps)
}

const SkipLinksWrapper = ({ display, zIndex = '0' }) => (
  <SkipLinksPanel display={display} zIndex={zIndex}>
    <SkipLink href='#main-content'>Skip to main content</SkipLink>
    <SkipLink href='#footer'>Skip to footer</SkipLink>
  </SkipLinksPanel>
)

const buildFooter = (data) => {
  const siteSettings = contentfulDataFormatter(
    data,
    'allContentfulSiteSettingsTree'
  )
  return buildComponent(siteSettings.props.defaultPageFooter)
}

const transformPage = ({ props: { data = {} } }) =>
  data.contentfulComponentTree
    ? JSON.parse(data.contentfulComponentTree.componentTree)
    : { props: {} }

const BaseLayout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        allContentfulNavigationTree {
          edges {
            node {
              componentTree
            }
          }
        }
        allContentfulSiteSettingsTree {
          edges {
            node {
              componentTree
            }
          }
        }
      }
    `}
    render={(data) => {
      const isNewPage = !!children.props.pageContext?.buildContext
        ?.componentTree

      const hideSideNav = isNewPage
        ? children.props.pageContext.buildContext.componentTree.props
            .hideSideNav
        : transformPage(children).props.hideSideNav

      return (
        <Providers>
          <Box
            name='page-wrapper'
            is='main'
            id='content'
            display='flex'
            flexDirection={['column']}
            minHeight='100vh'
            tabIndex='-1'
          >
            <SkipLinksWrapper
              display={['none', 'none', 'block']}
              zIndex={theme.zIndexes.skipLink}
            />
            <Box
              display='flex'
              flexDirection={['column', 'column', 'row']}
              minHeight='100vh'
            >
              <Box
                name='page-nav'
                width={['100%', '100%', columnWidth(2)]}
                zIndex={theme.zIndexes.menuBar}
                position='fixed'
              >
                <SkipLinksWrapper display={['block', 'block', 'none']} />
                {buildNavigation(data, { hideSideNav })}
              </Box>
              <Box name='page-content-wrapper' width='100%'>
                {children}
                <Box ml={[0, 0, hideSideNav ? 0 : columnWidth(2)]}>
                  {buildFooter(data)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Providers>
      )
    }}
  />
)

const Layout = withLocation(BaseLayout)
export default Layout
